import React, { useState, useEffect } from "react";
import logoLight from "./../../../assets/images/logo_light.png";
// import logoDark from "./../../../assets/images/logo_dark.png";
import MenuIcon from "@mui/icons-material/Menu";
// import { useLocation } from "react-router-dom";
import { NavLink } from "react-router-dom";
import useScrollTrigger from "@mui/material/useScrollTrigger";

const Navbar = () => {
  let logoUrl = logoLight;

  const [isActive, setActive] = useState();
  // eslint-disable-next-line no-unused-vars
  const [isSticky, setSticky] = useState();
  let getScrollStatus = useScrollTrigger({
    target: window,
    threshold: 20,
    disableHysteresis: true,
  });

  useEffect(() => {
    setSticky(getScrollStatus);
  }, [getScrollStatus]);
  const ToggleClass = () => {
    setActive(!isActive);
  };

  return (
    <>
      <nav className={isSticky ? "Navbar sticky" : "Navbar"}>
        <main className="max-width">
          <div className="logo">
            <NavLink to="/">
              <img
                src={logoUrl}
                alt="Automatic.lly Automatically Enterprise logo-light with title and tag line"
              />
            </NavLink>
          </div>
          <ul className={isActive ? "menu active" : "menu"}>
            <li>
              <a href="/#home" onClick={ToggleClass}>
                Home
              </a>
            </li>
            <li>
              <a href="/#services" onClick={ToggleClass}>
                Services
              </a>
            </li>
            <li>
              <a href="/#about" onClick={ToggleClass}>
                About Us
              </a>
            </li>
            <li>
              <NavLink to="/blog" onClick={ToggleClass}>
                Blog
              </NavLink>
            </li>
            <li>
              <a href="/#contact" className="btn" onClick={ToggleClass}>
                Contact Us
              </a>
            </li>
          </ul>
          <div className="menu-btn" onClick={ToggleClass}>
            <MenuIcon className="i" />
          </div>
        </main>
      </nav>
    </>
  );
};

Navbar.propTypes = {};

Navbar.defaultProps = {};

export default Navbar;
