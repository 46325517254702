import React from "react";
import { NavLink } from "react-router-dom";
import "./PortfolioCard.scss";

const PortfolioCard = (props) => {
  return (
    <>
      <div className="PortfolioCard card">
        <img src={props.imgSrc} alt={props.alt} />
        <div className="box">
          <h2>{props.heading}</h2>
          <p>{props.description}</p>
          {props.readMore === true && (
            <NavLink to={props.path}>Read More</NavLink>
          )}
        </div>
      </div>
    </>
  );
};

export default PortfolioCard;
