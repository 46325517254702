import React from "react";
// import PropTypes from "prop-types";
import Volly from "./../../../assets/images/volly.png";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";

const Home = () => {
  return (
    <>
      <section className="Home" id="home">
        <div className="max-width">
          <header className="home-content">
            <main className="column left">
              <div className="text-1">
                <h4 style={{ fontWeight: "normal" }}>
                  <FiberManualRecordIcon className="i" />
                  “We make it live”
                </h4>
              </div>
              <h1>From imagination to innovation!!</h1>
              <p>
                Automatically Enterprise is your one-stop IT solution provider.
                We have the expertise and the tech
              </p>
              <a href="#contact">Click Me</a>
            </main>
            <main className="column right">
              <img src={Volly} alt="Automatically Main home page Volley pic" />
            </main>
          </header>
        </div>
      </section>
    </>
  );
};

Home.propTypes = {};

Home.defaultProps = {};

export default Home;
