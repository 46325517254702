import React from "react";
import Card from "./../../../shared/ServiceCard.js";
import Sdata from "../../../assets/Data/ServiceData.js";
// import PropTypes from "prop-types";

const Services = () => {
  return (
    <>
      <section className="Services" id="services">
        <main className="max-width">
          <header className="title">OUR SERVICES</header>
          <article className="services-content">
            {Sdata.map((val, index) => (
              <Card
                key={val.id}
                heading={val.heading}
                description={val.description}
                image={val.image}
                alt={val.alt}
              />
            ))}
          </article>
        </main>
      </section>
    </>
  );
};
Services.propTypes = {};

Services.defaultProps = {};

export default Services;
