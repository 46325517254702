import React from "react";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TwitterIcon from "@mui/icons-material/Twitter";
import GoogleIcon from "./../../assets/images/google.svg";

const Social = () => {
  return (
    <>
      <section className="Social">
        <div className="max-width">
          <aside className="social-media">
            <a href="https://www.facebook.com/automatic.lly/">
              <FacebookIcon
                className="i"
                alt="facebook icon for link to facebook page"
              />
            </a>
            <a href="https://www.instagram.com/automatic.lly/">
              <InstagramIcon
                className="i"
                alt="instagram icon for link to instagram account"
              />
            </a>
            <a href="https://www.linkedin.com/company/automatic-lly">
              <LinkedInIcon
                className="i"
                alt="linkedin icon for link to linkedin page"
              />
            </a>
            <a href="https://twitter.com/automatic_lly">
              <TwitterIcon
                className="i"
                alt="twitter icon for link to twitter account"
              />
            </a>
            <a href="https://g.page/automatically?we">
              <img
                src={GoogleIcon}
                className="i G"
                alt="google icon for link to google search page"
              />
            </a>
          </aside>
        </div>
      </section>
    </>
  );
};

Social.propTypes = {};

Social.defaultProps = {};

export default Social;
