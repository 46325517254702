import React from "react";
// import PropTypes from "prop-types";
import ReactEmbedGist from "react-embed-gist";
import { useParams } from "react-router";

const GistContent = () => {
  const { gistId } = useParams();

  return (
    <>
      <section className="GistContent" id="gistContent">
        <main className="max-width">
          <ReactEmbedGist
            gist={`TengoCharlie/${gistId}`}
            wrapperClass="gist__bash"
            loadingClass="loading__screen"
            titleClass="title"
            errorClass="gist__error"
            contentClass="gist-content"
            file="Algorithm.md"
          />
        </main>
      </section>
    </>
  );
};

export default GistContent;

GistContent.propTypes = {};

GistContent.defaultProps = {};
