import React from "react";
import "./scss/main.scss";
import Navbar from "./Components/Layout/Navbar/Navbar.js";
import Footer from "./Components/Layout/Footer/Footer.js";
import FacebookMessenger from "./shared/FacebookMessenger.js";
import Homepage from "./Components/homepage/homepage";
import Blog from "./Components/Blog/Blog";

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import GistContent from "./Components/GistContent/GistContent";

const App = () => {
  return (
    <Router basename="/">
      <>
        <Navbar />
        <FacebookMessenger id="FaceBook-chat" />
        <Routes>
          <Route exact path="/" element={<Homepage />}></Route>
          <Route exact path="/blog" element={<Blog />}></Route>
          <Route path="/blog/:gistId" element={<GistContent />}></Route>
          <Route path="*" element={<Navigate to="/" replace={true} />}></Route>
        </Routes>

        <Footer />
      </>
    </Router>
  );
};

export default App;
