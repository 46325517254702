import React from "react";
import Home from "./Home/Home.js";
import Services from "./Services/Services.js";
import About from "./About/About.js";
import "./homepage.scss";
import Social from "./../../shared/Social/Social.js";
import Contact from "./../../shared/Contact/Contact.js";

const Homepage = () => {
  return (
    <>
      <Home />
      <Social />
      <About />
      <Services />
      <Contact />
    </>
  );
};

Homepage.propTypes = {};

Homepage.defaultProps = {};

export default Homepage;
