import React from "react";

const ServiceCard = (props) => {
  return (
    <>
      <div className="card">
        <img src={props.image} alt={props.alt} />
        <div className="box">
          <h2>{props.heading}</h2>
          <p>{props.description}</p>
        </div>
      </div>
    </>
  );
};

export default ServiceCard;
