import React from "react";
import Card from "../../shared/PortfolioCard/PortfolioCard";
import gistImage from "./../../assets/images/Web_Photo_Editor.jpg";
const gistList = [
  {
    id: 1,
    heading: "Say Hello To Algorithm",
    image: gistImage,
    alt: "Say Hello to algorithms",
    gistId: "1db842a300483d32058ecbfdb9142e4a",
  },
];

const Blog = () => {
  return (
    <>
      <section className="Blog" id="blog">
        <main className="max-width">
          <header className="title">Blogs</header>
          <article className="blog-content">
            {gistList.map((val, index) => (
              <Card
                key={val.id}
                heading={val.heading}
                imgSrc={val.image}
                alt={val.alt}
                readMore={true}
                path={"/blog/" + val.gistId}
              />
            ))}
          </article>
        </main>
      </section>
    </>
  );
};

Blog.propTypes = {};

Blog.defaultProps = {};

export default Blog;
