import React from "react";
// import PropTypes from "prop-types";

import emailjs from "emailjs-com";
import PhoneIcon from "@mui/icons-material/Phone";
import MailIcon from "@mui/icons-material/Mail";
import RoomIcon from "@mui/icons-material/Room";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import Snackbar from "@mui/material/Snackbar";

import Alert from "@mui/material/Alert";

const Contact = () => {
  const [open, setOpen] = React.useState(false);
  const [msg, setMsg] = React.useState("");
  const [type, setType] = React.useState("");

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  function sendEmail(e) {
    e.preventDefault();

    emailjs
      .sendForm(
        process.env.REACT_APP_EMAIL_SERVICE_ID,
        process.env.REACT_APP_EMAIL_TEMPLATE_ID,
        e.target,
        process.env.REACT_APP_EMAIL_PUBLIC_KEY
      )
      .then(
        (result) => {
          handleClick();
          setMsg("Message Sent! We will Response you ASAP.");
          setType("success");
          e.target.reset();
        },
        (error) => {
          console.log(error);

          handleClick();
          setMsg("Something Went wrong! please try again later");
          setType("error");
        }
      );
  }
  return (
    <>
      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          elevation={6}
          variant="filled"
          onClose={handleClose}
          severity={type}
        >
          <h1>{msg}</h1>
        </Alert>
      </Snackbar>

      <section className="Contact" id="contact">
        <main className="max-width">
          <div className="contact-content">
            <header className="box">
              <h2 className="title1">Contact With Us</h2>
              <a href="tel:+917690028089">
                <PhoneIcon className="i" />
                <span>+91-76900-28089</span>
              </a>
              <a href="https://wa.me/+917690028089">
                <WhatsAppIcon className="i" />
                <span>+91-76900-28089</span>
              </a>
              <a href="https://mail.google.com/mail/?view=cm&fs=1&to=support@automatically.cc">
                <MailIcon className="i" />
                <span>info@automatically.cc</span>
              </a>
              <a href="https://g.page/automatically?we">
                <RoomIcon className="i" />
                <span>Jaipur, Rajasthan</span>
              </a>
            </header>
            <div className="box">
              <form onSubmit={sendEmail}>
                <input
                  type="text"
                  name="user_name"
                  id="name"
                  placeholder="Your Name"
                  required
                />
                <input
                  type="email"
                  name="user_email"
                  id="email"
                  placeholder="Your E-mail"
                  required
                />
                <input
                  type="number"
                  name="contact_number"
                  id="contact"
                  placeholder="Your Mobile (+9176xxxxxx89)"
                  required
                />
                <textarea
                  name="message"
                  id="message"
                  cols={30}
                  rows={10}
                  placeholder="Your Message"
                  defaultValue={""}
                  required
                />
                <input type="submit" value="Send Message" id="a" />
              </form>
            </div>
          </div>
        </main>
      </section>
    </>
  );
};

Contact.propTypes = {};

Contact.defaultProps = {};

export default Contact;
