const Sdata = [
  {
    id: 1,
    heading: "Web Development",
    description:
      "we have an outstanding, diligent team of web development professionals who are persistently experimenting and implementing innovative ideas that will transform your website designs to make a killing out of your business. We provide a faster service to our clients for their convenience. ",
    image:
      "https://firebasestorage.googleapis.com/v0/b/webs-111.appspot.com/o/Web2.jpg?alt=media&token=22c0a022-b472-44e2-b059-0b8c29da400d",
    alt: "Website development graphic image",
  },

  {
    id: 2,
    heading: "App development",
    description:
      "we create impeccable and intuitive applications that are user friendly, smooth and simple. We develop all kinds of mobile apps for android, IOS etc. Our team for app development in coordination with UI and UX design team works together to provide our customers with excellent experience.  .",
    image:
      "https://firebasestorage.googleapis.com/v0/b/webs-111.appspot.com/o/App1.jpg?alt=media&token=a149d3fe-a26d-4f6f-a3f5-8e382632b4fe",
    alt: "Mobile application development graphics",
  },

  {
    id: 3,
    heading: "Design Services",
    description:
      "Graphic Design art and graphics are key sources to any business as it is for marketing. Online success if multifaceted in the modern age, while a site should be rich in text can help with search results, attracting visitors and manipulating them with attractive base and colorful different styles help the buyers better understand your product and service.  ",
    image:
      "https://firebasestorage.googleapis.com/v0/b/webs-111.appspot.com/o/Graphics1.jpg?alt=media&token=b91a1e6e-1c37-48d7-b4f4-d051f207d8a6",
    alt: "Graphic design and UI/UX design graphics",
  },

  {
    id: 4,
    heading: "Robotics",
    description:
      "Robotics is not just a stream or a branch it has become the whole and soul of many companies nowadays. It is expected to have a world full of robots taking the place of human workers. Hardware as well as software Robots are taking over the world, it’s not yet too much in demand but their need is increasing in companies from health care to defense.",
    image:
      "https://firebasestorage.googleapis.com/v0/b/webs-111.appspot.com/o/Robotics1.jpg?alt=media&token=af1d22c9-1a38-4853-9da9-9c6d6fb15fd5",
    alt: "Robotics and Automation Graphics",
  },

  {
    id: 5,
    heading: "CAD/CAM/CAE",
    description:
      "It helps users creating designs in either 2D or 3D so that they can visualize the construction, Optimize the designs, then analyse and then make them by smart CNC machines. CAD/CAM/CAE enables the development, modification, and optimization of the design process. CAD/CAM/CAE helping to create a more comprehensive designs",
    image:
      "https://firebasestorage.googleapis.com/v0/b/webs-111.appspot.com/o/Cad1.jpg?alt=media&token=2408f5a6-d5ca-4f99-83ec-5ccf0e1545dd",
    alt: "CAD, CAM, CAE graphics images",
  },

  {
    id: 6,
    heading: "Training",
    description:
      "With all these technologies taking over the world. we provides Training For all the above given services and other latest technologies as we all know how important to learn these Techs. Technology is taking over human resources and so we provides training in all the above mentioned fields with our highly professional instructors with great ease.",
    image:
      "https://firebasestorage.googleapis.com/v0/b/webs-111.appspot.com/o/Teaching1.jpg?alt=media&token=2d43979e-ff14-445d-a29b-a7cec3638c32",
    alt: "Training and teaching graphics",
  },
];

export default Sdata;
