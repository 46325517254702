import React from "react";
// import PropTypes from "prop-types";

const Footer = () => {
  const year = new Date().getFullYear();
  return (
    <>
      <footer>
        <div className="max-width">
          <main className="footer-content">
            <ul>
              <li>
                <a href="#home">Home</a>
              </li>
              <li>
                <a href="#services">Sevices</a>
              </li>
              <li>
                <a href="#about">About</a>
              </li>
              <li>
                <a href="#portfolio">Portfolio</a>
              </li>
              <li>
                <a href="#contact">Contact Us</a>
              </li>
            </ul>
            <p>
              All copyrights reserved © {year} - Designed &amp; Developed by
              Automatically Enterprise
            </p>
          </main>
        </div>
      </footer>
    </>
  );
};
Footer.propTypes = {};

Footer.defaultProps = {};

export default Footer;
