import React from "react";
// import PropTypes from "prop-types";

const About = () => {
  return (
    <>
      <section className="About" id="about">
        <main className="max-width">
          <header className="title">ABOUT US</header>
          <article className="about-content">
            <p>
              Automatically Enterprise is your one-stop IT solution provider. We
              have the expertise and the technology to create websites, apps,
              software, systems, IoTs, and 3D printing and designing projects
              that suit your needs and goals. We are quality-centric, meaning we
              always deliver the best material automation and innovative work
              for our clients. We also offer training and learning programs to
              help you master the latest IT skills and trends. Contact us today
              and let us transform your ideas into reality. We are a company
              with a vision to provide all types of IT services with beautiful
              designs and perfection. We started our journey in 2017 with a
              small team of passionate and talented professionals who shared a
              common goal: to make IT solutions accessible, affordable, and
              amazing for everyone. Since then, we have grown into a large and
              diverse team of experts who can handle any IT challenge or
              opportunity. We have completed six successful years in the IT
              industry, serving hundreds of clients from various sectors and
              regions. We have also partnered with some of the leading IT
              companies and organizations to deliver high-quality products and
              services. We have worked on numerous projects, ranging from simple
              websites to complex systems, from basic apps to advanced IoTs,
              from 3D printing to 3D designing. We have always exceeded our
              clients’ expectations and earned their trust and loyalty. We are
              proud of our achievements, but we are not complacent. We are
              always looking for new ways to improve our skills, our technology,
              and our solutions. We are always ready to take on new challenges
              and explore new possibilities. We are always eager to learn from
              our clients, our partners, and our peers. We are always motivated
              to innovate and create. We are Automatically Enterprise, and we
              are here to make your IT dreams come true.
            </p>
          </article>
        </main>
      </section>
    </>
  );
};

About.propTypes = {};

About.defaultProps = {};

export default About;
