import React from "react";

import { MessengerChat } from "react-messenger-chat-plugin";

function FacebookMessenger() {
  return (
    <>
      <MessengerChat
        pageId={process.env.REACT_APP_FACEBOOK_PAGE_ID}
        appId={process.env.REACT_APP_FACEBOOK_APP_ID}
      />
    </>
  );
}

export default FacebookMessenger;
